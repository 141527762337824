import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/(app)/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":[\"400\",\"900\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"preload\":true,\"variable\":\"--font-roboto\"}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Nav"] */ "/app/packages/web/src/app/(app)/nav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/app/packages/web/src/app/(app)/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CookieConsent"] */ "/app/packages/web/src/components/cookie-consent/cookie-consent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavLink"] */ "/app/packages/web/src/ui/common/navigation/nav-link/nav-link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileNavigation"] */ "/app/packages/web/src/ui/common/navigation/navigations/mobile-navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Profile"] */ "/app/packages/web/src/ui/common/navigation/profile/profile.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/web/styles/globals.css");
